export const nodeEnv = process.env.NODE_ENV || 'local';
export const aggregatorTokenBase = process.env.AGGREGATOR_TOKEN_BASE || 'aggregator-token-base';
export const stateSecret = process.env.STATE_SECRET || 'state-secret';
export const databaseUrl = process.env.DATABASE_URL;
export const sentryDsn = process.env.SENTRY_DSN;
export const sentryEnvironment = process.env.SENTRY_ENVIRONMENT || 'local_development';
export const browserBaseURL = process.env.BROWSER_BASE_URL || `http://localhost:${process.env.PORT || 3000}`;
export const baseURL = process.env.BASE_URL || browserBaseURL;
export const bigqueryBaseDataset = process.env.BIGQUERY_DATASET_BASE || 'seller_reporting_v2';
export const awsAccessKeyId = process.env.AWS_ACCESS_KEY_ID;
export const awsSecretAccessKey = process.env.AWS_SECRET_ACCESS_KEY;
export const awsRegion = process.env.SECRETS_MANAGER_REGION || 'eu-west-1';
export const sellerSyncUrl = process.env.SELLER_SYNC_URL || 'http://localhost:5000';
export const sellerSyncAuthUrl = process.env.SELLER_SYNC_AUTH_URL || 'https://seller-auth.dev.ix-io.net';
export const sellerDataApiUrl = process.env.SELLER_DATA_API_URL || 'http://localhost:5001';
export const productHistorianApiUrl = process.env.PRODUCT_HISTORIAN_API_URL || 'http://localhost:3100';
export const productHistorianPublicApiUrl = process.env.PRODUCT_HISTORIAN_PUBLIC_API_URL || 'http://localhost:3100';
export const copywriterApiUrl = process.env.COPYWRITER_API_URL || 'http://localhost:8006';
export const sellerAlertingApiUrl = process.env.SELLER_ALERTING_API_URL || 'http://localhost:8000';
export const docUrl = 'https://docs.autopilotbrand.com';
export const amazonPinpointApplicationId = process.env.AMAZON_PINPOINT_APPLICATION_ID || '47a9fdaa77b9414987e8e3f02c57b23d';
export const flatFileLicenseKey = process.env.FLAT_FILE_LICENCE_KEY;
export const fullStoryProductionMode = process.env.FULL_STORY_PRODUCTION_MODE;
export const slackToken = process.env.SLACK_TOKEN;
export const environment = process.env.ENVIRONMENT || 'local'; // 'local', 'test', 'dev', 'prod'
export const openToOffersRecipientEmail = process.env.OPEN_TO_OFFERS_RECIPIENT_EMAIL || 'dev@autopilotbrand.com';
export const sellerReportingSessionCookieName = process.env.SELLER_REPORTING_SESSION_COOKIE_NAME
  || 'seller-reporting-session-v5';
export const publicJwtHmacSecret = process.env.PUBLIC_JWT_HMAC_SECRET as string;
export const productHistorianJwtSecret = process.env.PRODUCT_HISTORIAN_JWT_SECRET as string;
export const supportEmail = process.env.SUPPORT_EMAIL as string || 'support@autopilotbrand.com';
export const timezone = process.env.TZ || 'UTC' as string;
export const gogoleClientId = process.env.GOOGLE_CLIENT_ID as string;
export const gogoleClientSecret = process.env.GOOGLE_CLIENT_SECRET as string;
export const googleAuthSessionStateSecret = (
  process.env.GOOGLE_AUTH_SESSION_STATE_SECRET as string
);
